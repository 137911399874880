.pagination {
    font-size: 12px;
    font-weight: 300;
    color: #999999;
}
.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 21px 0;
    border-radius: 0;
    border: none;
}

.pagination > li > a {
    margin-left: 4px;
    color: #999999;
}

.pagination > li > a, .pagination > li:first-child > a, .pagination > li:last-child > a, .pagination > li > span, .pagination > li:first-child > span, .pagination > li:last-child > span {
    border-radius: 3px;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    z-index: 3;
    color: #ffffff;
    background-color: #008cba;
    border-color:  transparent;
    cursor: default;
}

.pagination > li > a, .pagination > li > span {
    position: relative;
    float: left;
    padding: 8px 12px;
    line-height: 1.4;
    text-decoration: none;
    background-color: transparent;
    border: 1px solid transparent;
}