label{
    font-size: 12px;
    font-weight: normal;
}

.btn-primary{
    color: #ffffff;
    background-color: #3c9a5f;
    border-color: #3c9a5f;
}

.panel, input, button{
    border-radius: 0 !important;
}

.form-control{
    border-radius: 0;
}

.not_show ul, .multiselect-container{
    background-color: white;
}

.multiselect-container{
    top: 34px !important;
    transform: none !important;
    max-height: 500px;
    overflow-y: auto;
    width: 100%;
    padding: 16px;
}

.multiselect-native-select .multiselect, .multiselect-native-select .btn-group {
    width: 100%;
}

.multiselect.btn{
    white-space: normal;
}

span.multiselect-native-select select {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px -1px -1px -3px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    left: 50%;
    top: 30px;
}
