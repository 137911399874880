.nav-tabs {
    border-bottom: 1px solid
    #dddddd;
}

.nav-tabs > li {
    float: left;
    margin-bottom: -1px;
}
.nav > li {
    position: relative;
    display: block;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    color: #6f6f6f;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-bottom-color: transparent;
    cursor: default;
}
.nav-tabs > li > a {
    background-color: #e7e7e7;
    color: #222222;
}
.nav-tabs > li > a {
    margin-right: 2px;
    line-height: 1.4;
    border: 1px solid transparent;
    border-radius: 0 0 0 0;
}
.nav > li > a {
    position: relative;
    display: block;
    padding: 10px 15px;
}

.mainSearch.btn a, .mainSearch.btn a:hover{
    display: inline-block;
    color: white;
    text-decoration: none;
    padding: 6px 12px;
}

.mainSearch.btn.btn-primary{
    background-color: #3c9a5f;
    border-color: #3c9a5f;
    padding: unset;
}