.breadcrumb a:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

.breadcrumb a:hover, a:focus {
    color: #008cba;
    text-decoration: underline;
}

.breadcrumb a:active, a:hover {
    outline: 0;
}

.breadcrumb a {
    color: #008cba;
    text-decoration: none;
    background-color: transparent;
}