body{
  font-family: "Tahoma", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.4;
  font-size: 15px;
  color: #222222;
}

h2, h4{
  font-weight: 300;
}

h2{
  font-size: 32px;
}

h4{
  font-size: 19px;
}