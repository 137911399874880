.navbar{
    font-size: 13px;
    min-height: 45px;
}
.navbar-brand{
    padding-top: 4px;
    height: 45px;
}
.navbar-default{
    background-color: #3c9a5f;
}
.navbar-default .navbar-nav > li > a {
    color: #ffffff;
}
.navbar-nav > li > .dropdown-menu {
    border-radius: 0;
    border: none;
}
.navbar-default .dropdown-menu {
    background-color: #3c9a5f;
}
.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
    background-color: #3c763d;
    color: #ffffff;
}
.navbar-default .dropdown-menu > li > a:hover, .navbar-default .dropdown-menu > .active > a, .navbar-default .dropdown-menu > .active > a:hover {
    background-color: #3c763d;
}
.navbar .dropdown-menu > li > a, .navbar .dropdown-menu > li > a:focus {
    font-size: 13px;
    font-weight: 300;
}
.navbar-default .navbar-nav .nav-link {
    color: #ffffff;
}
.navbar-nav .nav-link {
    padding-top: 12px;
    padding-bottom: 12px;
    line-height: 21px;
}
.nav-item .nav-link {
    position: relative;
    display: block;
    padding: 12px 15px;
}


.dropdown-menu{
    padding-bottom: 0px;
    padding-top: 0px;
    margin: 0;
    border: none;
}

.caret {
    color: white;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > li > a:hover {
    color: white !important;
}

.subMenu{
    padding: 0;
    color: white;
}

.dropdown-menu{
    border-radius: 0;
    font-size: 13px;
    width: 100%;
}

.subMenu > li:hover, .show{
    background-color: #3c763d;
}

.subMenu > li {
    padding: 12px 15px;
}

.nav a, .nav a:hover, .nav a:focus{
    color: white;
}

.nav li{
    list-style: none;
}

.nav a:hover, .nav a:focus{
    text-decoration: none !important;
}

#navbar-main{
    background-color: #3c9a5f;
    border-color: black;
    overflow-y: auto;
}
.navbar-default .navbar-toggle{
    border-color: transparent;
}

.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
    background-color: transparent;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: #b2dba1;
}

.navbar-default .navbar-toggle:hover .icon-bar{
    background-color: #b3b3b3;
}

div.dropdown-menu {
  position: unset !important;
}

@media only screen and (max-width: 767px) {
    div.dropdown-menu {
        position: unset !important;
    }
    div.show {
        transform: none !important;
    }
}

@media only screen and (min-width: 768px) {
    div.dropdown-menu {
        position: absolute !important;
    }
}